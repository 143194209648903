<template>
  <div
    class="div uk-padding uk-border-rounded"
    style="border: 1px solid black; position: relative">
    bogle component
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
